body[data-theme="dark"] {
    @import '../src/scss/dark-slider.scss';
    .slider-tab {
        background-color: #8C8C8C;
    }
    .title-styles {
        color: white;
    }
    .header-icon {
        color: #3C342C;
        opacity: 0.8;
    }
    .slider-image {
        border: 5px solid #8C8C8C;
    }
    .language {
        background-color: #494949;
    }
    .link-href {
        color: white;
    }
    .project-date {
        background-color: #918e97;
        color: white;
    }
    header {
        background-color: #494949;
        h1 {
            color: white;
        }
    }
    #about {
        background-color: #7f7f7f;
        h1 span {
            color: white;
        }
        .polaroid span {
            background: #6d6d6d;
        }
        .card {
            background: #6d6d6d;
            color: white;
        }
    }
    #portfolio {
        background: #5b5b5b;
        .section-title {
            color: white !important;
        }
        .foto div {
            background: #6d6d6d;
        }
        .project-title-settings {
            color: white;
        }
    }
    #resume {
        filter: brightness(80%);
        background: #5b5b5b;
        .section-title {
            color: white !important;
        }
        .experience-badge {
            background: #919191 !important;
            color: white;
        }
        .main-badge {
            background: #919191 !important;
        }
        .vertical-timeline-element-date {
            color: white;
        }
        .vertical-timeline-element-icon {
            background: #919191 !important;
        }
        @media only screen and (max-width: 1169px) {
            .vertical-timeline-element-date {
                color: black;
            }
        }
    }
    .modal-inside .modal-content {
        background: #5b5b5b;
        color: white;
    }
    .close-icon {
        color: white;
    }
}